export default [
  {
    path: '/curso/ruso/',
    name: 'curso-ruso',
    component: () => import(/* webpackChunkName: "CursoRuso" */ '@/views/course/Index.vue'),
    meta: {
      pageTitle: 'Curso',
      resource: 'cursos',
      action: 'read',
      breadcrumb: [
        {
          text: 'Curso',
        },
        {
          text: 'ruso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/curso/checo/',
    name: 'curso-checo',
    component: () => import(/* webpackChunkName: "CursoCheco" */ '@/views/course/IndexCheco.vue'),
    meta: {
      pageTitle: 'Curso',
      resource: 'cursos',
      action: 'read',
      breadcrumb: [
        {
          text: 'Curso',
        },
        {
          text: 'checo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/curso/ingles/',
    name: 'curso-ingles',
    component: () => import(/* webpackChunkName: "CursoCheco" */ '@/views/course/IndexIngles.vue'),
    meta: {
      pageTitle: 'Curso',
      resource: 'cursos',
      action: 'read',
      breadcrumb: [
        {
          text: 'Curso',
        },
        {
          text: 'Inglés',
          active: true,
        },
      ],
    },
  },
]
