export default [
  {
    path: '/family/list',
    name: 'family-list',
    component: () => import(/* webpackChunkName: "Analytics" */ '@/views/family/FamilyView.vue'),
    meta: {
      pageTitle: 'Acudientes',
      resource: 'familiares',
      action: 'read',
      breadcrumb: [
        {
          text: 'Acudientes',
        },
        {
          text: 'Ver todos',
          active: true,
        },
      ],
    },
  },
]
