export default [
  {
    path: '/roles-permisos',
    name: 'roles-permisos',
    component: () => import(/* webpackChunkName: "roles" */ '@/views/rolesPermisos/roles.vue'),
    meta: {
      resource: 'config',
      action: 'read',
      breadcrumb: [
        {
          text: 'Roles de usuarios',
        },
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles-permisos/create',
    name: 'roles-permisos-create',
    component: () => import(/* webpackChunkName: "formRole" */ '@/components/rol/form.vue'),
    meta: {
      resource: 'config',
      action: 'create',
      breadcrumb: [
        {
          text: 'Roles de usuarios',
          to: '/roles-permisos',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles-permisos/edit/:id',
    name: 'roles-permisos-edit',
    component: () => import(/* webpackChunkName: "formRoleEdit" */ '@/components/rol/form.vue'),
    meta: {
      resource: 'config',
      action: 'update',
      breadcrumb: [
        {
          text: 'Roles de usuarios',
          to: '/roles-permisos',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-contract',
    name: 'type-contract',
    component: () => import(/* webpackChunkName: "typeContract" */ '@/views/typeOptions/TypeContract/Index.vue'),
    meta: {
      resource: 'config',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de contrato',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/fecha-viaje',
    name: 'fecha-viaje',
    component: () => import(/* webpackChunkName: "FechaViaje" */ '@/views/FechaViaje/FechaViaje.vue'),
    meta: {
      resource: 'fechaviaje',
      action: 'read',
      breadcrumb: [
        {
          text: 'Fecha de viaje',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-financing',
    name: 'type-financing',
    component: () => import(/* webpackChunkName: "IndexFinancing" */ '@/views/typeOptions/TypeFinancy/Index.vue'),
    meta: {
      resource: 'tipofinancion',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de financiación',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-stado-civil',
    name: 'type-stado-civil',
    component: () => import(/* webpackChunkName: "IndexCivil" */ '@/views/typeOptions/TypeStadoCivil/Index.vue'),
    meta: {
      resource: 'tipostadocivil',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de estado civil',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-studio',
    name: 'type-studio',
    component: () => import(/* webpackChunkName: "IndexEstudio" */ '@/views/typeOptions/TypeStudy/Index.vue'),
    meta: {
      resource: 'tipostadocivil',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de estado civil',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-grado-academico',
    name: 'type-grado-academico',
    component: () => import(/* webpackChunkName: "IndexAcademico" */ '@/views/typeOptions/TypeGradoAcademico/Index.vue'),
    meta: {
      resource: 'tipogradoacademico',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de grado académico',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-cargo',
    name: 'type-cargo',
    component: () => import(/* webpackChunkName: "IndexCargo" */ '@/views/typeOptions/TypeCargo/Index.vue'),
    meta: {
      resource: 'tipocargo',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de cargo',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-documents',
    name: 'type-documents',
    component: () => import(/* webpackChunkName: "IndexDocuments" */ '@/views/typeOptions/TypeDocument/Index.vue'),
    meta: {
      resource: 'tipodocumento',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de documento',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-gender',
    name: 'type-gender',
    component: () => import(/* webpackChunkName: "IndexGender" */ '@/views/typeOptions/TypeGender/Index.vue'),
    meta: {
      resource: 'gender',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de genero',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-regalo',
    name: 'type-regalo',
    component: () => import(/* webpackChunkName: "IndexRegalo" */ '@/views/typeOptions/TypeRegalos/Index.vue'),
    meta: {
      resource: 'giff',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de regalo',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-status',
    name: 'type-status',
    component: () => import(/* webpackChunkName: "IndexStatus" */ '@/views/Status/Index.vue'),
    meta: {
      resource: 'status',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de estados',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/type-client',
    name: 'type-client',
    component: () => import(/* webpackChunkName: "IndexClient" */ '@/views/typeOptions/TypeClient/Index.vue'),
    meta: {
      resource: 'typeclient',
      action: 'read',
      breadcrumb: [
        {
          text: 'Tipo de clientes',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/documents-type',
    name: 'documents-type',
    component: () => import(/* webpackChunkName: "IndexDocumentos" */ '@/views/documents/IndexDocumentsConfig.vue'),
    meta: {
      resource: 'documento',
      action: 'read',
      breadcrumb: [
        {
          text: 'Documentos',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/config-contract',
    name: 'config-contract',
    component: () => import(/* webpackChunkName: "IndexContract" */ '@/views/configContrato/Index.vue'),
    meta: {
      resource: 'contrato',
      action: 'read',
      breadcrumb: [
        {
          text: 'Configuración de contrato',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/config-contract-version',
    name: 'config-contract-version',
    component: () => import(/* webpackChunkName: "IndexContract" */ '@/views/contractVersion/Index.vue'),
    meta: {
      resource: 'contrato',
      action: 'read',
      breadcrumb: [
        {
          text: 'Versiones de contratos',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/config-country-city',
    name: 'config-country-city',
    component: () => import(/* webpackChunkName: "IndexCitypais" */ '@/views/configCountry/Index.vue'),
    meta: {
      resource: 'pais',
      action: 'read',
      breadcrumb: [
        {
          text: 'Configuración de pais, ciudad, municipio',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/config-universidad',
    name: 'config-universidad',
    component: () => import(/* webpackChunkName: "IndexUniversidad" */ '@/views/university/Index.vue'),
    meta: {
      resource: 'universidad',
      action: 'read',
      breadcrumb: [
        {
          text: 'Configuración de universidad',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/config-opcion',
    name: 'config-opcion',
    component: () => import(/* webpackChunkName: "IndexUniversidad" */ '@/views/opcion/Tabs.vue'),
    meta: {
      resource: 'config',
      action: 'read',
      breadcrumb: [
        {
          text: 'Configuración general',
        },
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/type-contract/create',
  //   name: 'type-contract-create',
  //   component: () => import('@/views/typeContract/form.vue'),
  //   meta: {
  //     resource: 'tipocontrato',
  //     action: 'create',
  //     breadcrumb: [
  //       {
  //         text: 'Tipo de contrato',
  //       },
  //       {
  //         text: 'Crear',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
