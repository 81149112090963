import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import UserCalender from '@/views/apps/calendar/calendarStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    userApp: user,
    // calendar: UserCalender,
  },
  strict: process.env.DEV,
})
