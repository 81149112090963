export default [
  {
    path: '/documents/list',
    name: 'documents-list',
    component: () => import(/* webpackChunkName: "Analytics" */ '@/views/documents/IndexDocumentsView.vue'),
    meta: {
      pageTitle: 'Documentos',
      resource: 'documentosestudiante',
      action: 'read',
      breadcrumb: [
        {
          text: 'Documentos',
        },
        {
          text: 'Todos los documentos',
          active: true,
        },
      ],
    },
  },
]
