export default {
  namespaced: true,
  state: {
    fullName: '',
    username: '',
    user_tipo_cargo: '',
    role: '',
    avatar: '',
  },
  getters: {
    getProfileData(state) {
      return {
        fullName: state.fullName,
        user_tipo_cargo: state.user_tipo_cargo,
        role: state.role,
        username: state.username,
        avatar: state.avatar,
      }
    },
  },
  mutations: {
    SET_PROFILE_DATA(state, profileData) {
      state.user_tipo_cargo = profileData.user_tipo_cargo
      state.fullName = profileData.fullName
      state.username = profileData.username
      state.role = profileData.role
      state.avatar = profileData.avatar
    },
  },
  actions: {
    setProfileData(context, profileData) {
      context.commit('SET_PROFILE_DATA', profileData)
    },
  },
}
