export default [
  {
    path: '/users/view/',
    name: 'apps-users-view',
    meta: {
      pageTitle: 'Información del usuario',
      resource: 'profile',
      action: 'read',
      breadcrumb: [
        {
          text: 'Usuario',
        },
        {
          text: 'perfil',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "UsersView" */ '@/views/users/user/users-view/UsersView.vue'),
  },
  {
    path: '/users/edit/:id',
    name: 'apps-users-edit',
    meta: {
      pageTitle: 'Editar usuario',
      resource: 'admin',
      action: 'update',
      breadcrumb: [
        {
          text: 'Usuario',
        },
        {
          text: 'editar',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/users/form.vue'),
  },
  {
    path: '/users/crear',
    name: 'apps-users-crear',
    meta: {
      pageTitle: 'Crear usuario',
      resource: 'admin',
      action: 'create',
      breadcrumb: [
        {
          text: 'Usuario',
        },
        {
          text: 'crear',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "UsersCrear" */ '@/views/users/form.vue'),
  },
  {
    path: '/users/list/',
    name: 'users-list',
    meta: {
      pageTitle: 'Listado de estudiantes',
      resource: 'users',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'estudiantes',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "IndexUser" */ '@/views/users/IndexRemoteUser.vue'),
  },
  {
    path: '/users/list/all',
    name: 'users-list-all',
    meta: {
      pageTitle: 'Listado de usuarios',
      resource: 'users',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'todos los usuarios',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "IndexUserAll" */ '@/views/users/IndexUser.vue'),
  },
  {
    path: '/users/traveler/',
    name: 'users-viaje',
    meta: {
      pageTitle: 'Información del viaje',
      resource: 'viaje',
      breadcrumb: [
        {
          text: 'Editar información del viaje',
        },
        {
          text: 'editar',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "IndexUserViaje" */ '@/views/users/traveler/TravelerViewEdit.vue'),
  },
  {
    path: '/users/profile/',
    name: 'users-profile',
    meta: {
      pageTitle: 'Información del usuario',
      resource: 'profile',
      breadcrumb: [
        {
          text: 'Editar perfil',
        },
        {
          text: 'editar',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "IndexUserProfile" */ '@/views/users/profile/ProfileViewEdit.vue'),
  },
  {
    path: '/users/contract/',
    name: 'users-contract',
    meta: {
      pageTitle: 'Información del contrato',
      resource: 'contrato',
      breadcrumb: [
        {
          text: 'Editar información del contrato',
        },
        {
          text: 'editar',
          active: true,
        },
      ],
    },
    component: () => import(/* webpackChunkName: "IndexUserContract" */ '@/views/users/contract/ContractViewEdit.vue'),
  },
]
